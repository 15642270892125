<template>
  <v-dialog
    :width="$store.getters['auth/isStudio'] ? '450' : '500'"
    v-model="dialog"
  >
    <v-card id="Token">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row style="width: 100%; margin: 0 auto">
          <v-col
            id="time"
            cols="12"
            md="3"
            lg="5"
            style="margin-top: -20px"
            class="py-md-3 pb-0 pt-0"
          >
            <label v-text="$t('events.date')" />
            <v-menu
              v-model="menu"
              transition="scale-transition"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-validate="'required'"
                  data-vv-name="startDate"
                  :error-messages="errors.collect('reserva-form.startDate')"
                  class="filters"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="date ? $d(new Date(date), 'date2digits') : ''"
                  ><template v-slot:prepend-inner>
                    <!--Aqui va el v-icon -->
                    <v-icon
                      small
                      class="pt-0 pb-1"
                      style="margin-top: 5px"
                      color="primary"
                      >$appointments</v-icon
                    >
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="date"
                no-title
                scrollable
                color="primary"
                :min="today"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-select
              :items="hoursRow"
              v-model="res.start"
              :label="$t('events.startTime')"
              dense
              outlined
              hide-details
              data-vv-validate-on="blur"
              v-validate="'required'"
              @change="fetchBoxes()"
              v-bind:class="{ 'error--text': timeError }"
              data-vv-name="startTime"
              :error-messages="errors.collect('reserva-form.startTime')"
              style="display: inline-block; width: 45%"
            ></v-select>
            <p
              class="pa-1"
              style="
                display: inline-block;
                width: 10%;
                text-align: center;
                color: var(--v-primary-base);
              "
            >
              -
            </p>
            <v-select
              :items="hoursRow"
              v-model="res.end"
              :label="$t('events.endTime')"
              hide-details
              v-bind:class="{ 'error--text': timeError }"
              dense
              data-vv-validate-on="blur"
              :v-validate="this.res.associate ? '' : 'required'"
              :error-messages="errors.collect('reserva-form.endTime')"
              data-vv-name="endTime"
              @change="fetchBoxes()"
              outlined
              style="display: inline-block; width: 45%"
            ></v-select>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headers"
          :items="items"
          :search.sync="filters.search"
          :options.sync="options"
          :loading="loading"
          :server-items-length="totalBoxes"
        >
          <template v-slot:item.studio_id="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-if="item.studio_id != null" v-on="on" v-bind="attrs">
                  {{ boxesHoursDay(item.id).hour }}h
                </span>
              </template>
              <span>
                {{ boxesHoursDay(item.id).hour }}
                {{ $t("hours_available") }}</span
              >
            </v-tooltip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status != null"
                  v-on="on"
                  v-bind="attrs"
                  :style="
                    boxesHoursDay(item.id).percentage >= 0.5
                      ? boxesHoursDay(item.id).percentage >= 0.75
                        ? boxesHoursDay(item.id).percentage == 1
                          ? 'color:red; cursor:pointer'
                          : 'color:orange; cursor:pointer'
                        : 'color:yellow; cursor:pointer'
                      : 'cursor:pointer'
                  "
                  >{{ $n(boxesHoursDay(item.id).percentage, "percent") }}
                </span>
              </template>
              <span
                >{{ $n(boxesHoursDay(item.id).percentage, "percent") }}
                {{ $t("ocupation") }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "BoxFilterCard",
  props: ["disabled", "res"],
  watch: {
    options: {
      handler() {
        this.fetchBoxes();
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      let array = [
        {
          text: this.$t("box"),
          align: "center",
          sortable: false,
          value: "name",
        },
      ];

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        sortable: false,
        value: "status",
      });
      array.push({
        text: this.$t("boxes.hours_available"),
        align: "center",
        sortable: false,
        value: "studio_id",
      });

      return array;
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = this.timeStudio[0][0][0];
        let minFinal = null;

        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              if (this.timeStudio[i][k][q] <= min) {
                min = this.timeStudio[i][k][q];
                minFinal = min;
                //console.log(minFinal);
              }
            }
          }
        }
        let final = minFinal.split(":");
        //console.log(final);
        let fin = final[0];
        if (fin.indexOf("0") != -1) {
          fin = fin.substr(1, 1);
        }
        //console.log(fin);
        return fin;
      }
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = this.timeStudio[0][1][1];
        let maxFinal = null;

        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              //console.log("%c%s", "color: #00a3cc", max);
              if (this.timeStudio[i][k][q] >= max) {
                max = this.timeStudio[i][k][q];
                maxFinal = max;
                //console.log(maxFinal);
              }
            }
          }
        }
        let final = maxFinal.split(":");
        //console.log(final[0] - this.timeFrom);

        return final[0];
      }
    },
    hoursRow() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":30"];
        let hours = [];
        let first = parseInt(this.timeFrom);
        //console.log(first);
        for (let x = first; x < this.timeTo; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        //console.log(hours);
        return hours;
      }
    },
    timeError() {
      if (this.res.start != undefined && this.res.end != undefined) {
        let s = this.res.start.split(":");
        let e = this.res.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] >= e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      dialog: false,
      timeStudio: [],
      date: null,
      menu: false,
      totalBoxes: 0,
      box: {
        id: null,
        name: null,
        status: null,
        start: null,
        end: null,
        date: new Date().toISOString().substr(0, 10),
        studio_id: this.$store.state.auth.user.studio.id,
      },
      items: [],
      loading: false,
      options: {},
      statusList: [],
      filters: {
        search: "",
        dates: "",
        tattooers: "",
      },
    };
  },

  mounted() {
    this.today = new Date().toISOString().substr(0, 10);
    this.fetchBoxes();
    this.fetchStatusList();
    this.fetchTimeStudio();
  },
  methods: {
    ...mapActions("boxes", ["getBoxes", "getTable"]),
    ...mapActions("studios", ["getStudio"]),
    fetchStatusList() {
      this.loading = true;

      this.getTable({ date: this.today, studio: this.box.studio_id }).then(
        (boxes) => {
          //console.log(boxes.box);
          this.statusList = boxes.box;

          this.loading = false;
        }
      );
    },
    fetchTimeStudio() {
      this.getStudio(this.box.studio_id).then((data) => {
        //console.log(data);
        this.timeStudio = data.timetable;
        //console.log(this.timeStudio);
      });
    },
    fetchBoxes() {
      this.loading = true;
      this.getBoxes({
        pagination: this.options,
        filters: this.filters,
      }).then((boxes) => {
        //console.log(boxes.data);
        this.totalBoxes = boxes.total;
        this.items = boxes.data;

        this.loading = false;
      });
    },
    boxesHoursDay(box) {
      let n = this.statusList.find((element) => element.id == box);

      if (n) {
        let hs = n.boxes_tattooer.filter((x) => x.start_date == this.date);

        if (hs) {
          let span = [];
          let n = 0;
          let t = hs.filter((x) => {
            if (this.res.end >= x.start_time && this.res.start < x.end_time) {
              //console.log("hola 2");
              return this.res.end > x.start_time && this.res.start < x.end_time;
            }
          });

          let s = 0;
          let start = 0;
          let end = 0;
          for (let i = 0; i < this.hoursRow.length; i++) {
            if (this.hoursRow[i] == this.res.start) {
              start = i;
            } else if (this.hoursRow[i] == this.res.end) {
              end = i;
            }
          }
          hs.filter((k) => {
            if (this.res.end > k.start_time && this.res.start < k.end_time) {
              for (let i = 0; i < this.hoursRow.length; i++) {
                if (this.hoursRow[i] == k.start_time) {
                  s = i;
                } else if (this.hoursRow[i] == k.end_time) {
                  if (s >= start && i <= end) {
                    span.push(i - s);
                    //  //console.log(s);
                  } else if (s <= start && i <= end) {
                    // //console.log(s);
                    span.push(i - (start - s));
                  } else if (s <= start && i >= end) {
                    // //console.log(s);
                    span.push(end - start);
                  } else if (s >= start && i >= end) {
                    ////console.log(s);
                    span.push(i - end - s);
                  }
                }
              }
            }
          });
          //   //console.log(span);
          // let kk = t.filter((k) => {
          //   if (this.res.start <= k.start_time && this.res.end > k.end_time) {
          //     //console.log("hola 1");
          //     return (
          //       this.res.start <= k.start_time && this.res.end > k.end_time
          //     );
          //   }
          // });

          let p = null;
          for (let index = 0; index < span.length; index++) {
            p += span[index];
            //console.log(span[index]);
          }
          //console.log(p);
          if (t) {
            ////console.log(t);
            // let percentage = t.length;
            //let hours = t.length;
            //let percentage = p;
            //console.log(end - start);
            let percentage = p / end;
            let hours = p / 2 - (this.res.end - this.res.start);
            return { percentage: percentage, hour: Math.abs(hours) };
          }
        }
      }
      return { percentage: null };
    },
    rowspanTattooer(h, day) {
      let c = this.boxWeekHours[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
<style lang="sass">
.cusList
  height: 30px
.v-list-item__icon
  margin-right: 5px !important

.cusList .v-icon, .cusList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)
#time
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
  margin-top: 8px !important
#Token
</style>